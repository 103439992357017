import "@fontsource/roboto"
import "./src/styles/foundation/variable.css"
import "./src/styles/foundation/normalize.css"
import "./src/styles/foundation/base.css"
import "./src/styles/foundation/blog-contents-style.css"
import { navigate } from "gatsby"

export const onRouteUpdate = ({ location }) => {
    if (location.pathname === "/salon/fulltime/") {
        navigate("/", { replace: true }); // navigate を使ってリダイレクト
    }
    if (location.pathname === "/salon/parttime/") {
        navigate("/", { replace: true }); // navigate を使ってリダイレクト
    }
    
};

export const onInitialClientRender = () => {
  // console.log("Blog widget scripts are being injected");

	// 指定したクラス名の要素を取得
  const targetElement = document.querySelector(".blogWidget_box");

  
  if (!targetElement) {
    console.warn("指定したクラス名の要素が見つかりません。");
    return;
  }

  // jQuery をロード
  const jqueryScript = document.createElement("script");
  jqueryScript.src = "https://ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js";
  jqueryScript.async = true;

  // Mitsuraku のブログウィジェットスクリプトをロード
  const widgetScript = document.createElement("script");
  widgetScript.src = "https://widget.mitsuraku.jp/js/ajax/blogWidget.js";
  widgetScript.async = true;

  // jQuery ロード完了後に blogWidget を追加
  jqueryScript.onload = () => {
    console.log("jQuery loaded, now loading blogWidget.js");

    // `<div id="blogWidget">` を作成して追加
    const widgetDiv = document.createElement("div");
    widgetDiv.id = "blogWidget";

    // `<input>` 要素を追加
    const shopIdInput = document.createElement("input");
    shopIdInput.type = "hidden";
    shopIdInput.id = "blogWidget-shop_id";
    shopIdInput.value = "94116";

    const passwordInput = document.createElement("input");
    passwordInput.type = "hidden";
    passwordInput.id = "blogWidget-password";
    passwordInput.value = "LXC4nlC9r";

    // `<div id="blogWidget">` に `<input>` を追加
    widgetDiv.appendChild(shopIdInput);
    widgetDiv.appendChild(passwordInput);

    // **ターゲット要素に追加**
    targetElement.appendChild(widgetDiv);

    // Mitsuraku スクリプトを追加
    targetElement.appendChild(widgetScript);
  };

  // **ターゲット要素に jQuery を追加**
  targetElement.appendChild(jqueryScript);
};
